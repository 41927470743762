export const VALID_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/tiff',
];

export const KOIREADER_SUPPORT_EMAIL = 'support@koireader.com';

export const MAX_FILE_SIZE_UPLOAD_LIMIT = 8_000_000;

export const DOCUMENT_COLORS = [
  '#8d7fff',
  '#ff4eb8',
  '#76ffc4',
  '#ff3d6b',
  '#0075fa',
  '#ffe4b4',
  '#faa72b',
  '#00a0d0',
  '#f5aed6',
  '#ff5500',
  '#aaff68',
  '#a5edff',
  '#e6ffab',
  '#ffa8a8',
  '#eb6dff',
  '#cdcdcd',
  '#ae43ff',
  '#ff6972',
  '#3bdaf3',
  '#ffe24e',
];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const MINIMUM_DATE_TIMESTAMP = 946_688_400_000;

export enum JobStatus {
  all = 'all',
  queued = 'queued',
  digitized = 'digitized',
  approved = 'approved',
  tms = 'in_third_party_process',
  processed = 'processed',
  notProcessed = 'not_processed',
  // pendingApproval = 'pending_approval',
  // partiallyProcessed = 'partially_processed',
}

export enum Events {
  jobCreated = 'jobCreated',
  jobDeleted = 'jobDeleted',
  formRefresh = 'form.refresh',
  tableOpen = 'tableOpen',
  tableClosed = 'tableClosed',
  documentImageRefresh = 'documentImage.refresh',
  documentAssigned = 'documentAssigned',
  updateJobCount = 'updateJobCount',
  imageCredentialsExpired = 'imageCredentialsExpired',
  subscriptionResponse = 'subscriptionResponse',
}

export enum FormGroupLabels {
  basicInformation = 'Basic Information',
  containerDetails = 'Container Details',
  tables = 'Tables',
  containerTable = 'Container Table',
  addresses = 'Addresses',
  events = 'Events',
  chassisDetails = 'Chassis Details',
  location = 'Location',
  routing = 'Routing',
  chargeables = 'Chargeables',
  shippingInformation = 'Shipping Information',
  appointmentDetails = 'Appointment Details',
}

export enum SettingsTabs {
  businessRules = 'businessRules',
}
